import React from 'react'

import '../stylesheets/components/App.scss'

export default class App extends React.Component {
    state = {
        status: new Date() > new Date('2030-05-13 00:00:00'),
    }
    render() {
        return (
            <div className="App">
                {!!this.state.status ? (
                    <div className="App_status App_yes">
                        <img src="/yes.svg" alt="" />
                        <p>Ja zeker!</p>
                    </div>
                ) : (
                    <div className="App_status App_no">
                        <p>Nope</p>
                        <img src="/no.svg" alt="" />
                    </div>
                )}
            </div>
        )
    }
}
